import Button from '@/components/Button'
import InputSelect from '@/components/InputSelect'
import useAlert from '@/hooks/useAlert'
import { useCreateContact } from '@/services/hooks/Contact/useCreateContact'
import { signFailure } from '@/store/modules/auth/actions'
import { palette } from '@/styles/theme'
import { phoneMask } from '@/utils/format'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Checkbox, InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMixpanel } from 'react-mixpanel-browser'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { companySizeOptions } from '../RequestContactDialog/rules'

type Inputs = {
  name: string
  email: string
  phone: string
  role: string
  companyName: string
  companySize: string
  providerSoftware: boolean
  termsAndCondition: boolean
}

const RequestContactForm: React.FC<{
  email?: string
  source: string
  postSubmit?: () => void
}> = ({ email, source, postSubmit }) => {
  const { showAlert } = useAlert()
  const mixpanel = useMixpanel()
  const dispatch = useDispatch()
  const createContact = useCreateContact()

  const schema = yup.object({
    name: yup.string().required('Nome deve ser preenchido.'),
    email: email
      ? yup.string()
      : yup.string().email().required('Email não pode ficar em branco.'),
    phone: yup
      .string()
      .required('Número de telefone obrigatório.')
      .min(15, 'Número de telefone inválido.')
      .max(15, 'Número de telefone inválido.'),
    companyName: yup.string().required('Digite o nome da sua empresa.'),
    companySize: yup.string().required('Selecione o tamanho da sua empresa.'),
    termsAndCondition: yup.boolean().required(),
  })

  const { handleSubmit, control, formState } = useForm<Inputs>({
    resolver: yupResolver(schema),
  })

  const handleRequest = async (data: {
    name: string
    email: string
    phone: string
    role: string
    companyName: string
    companySize: string
    providerSoftware: boolean
  }) => {
    showAlert('Enviando...', 'info')

    try {
      await createContact.mutateAsync(data)
      mixpanel.track(`SendContactRequest${source}`)
      if (email) {
        setTimeout(function () {
          dispatch(signFailure())
        }, 6001)
      }
      showAlert(
        'Obrigado pelo interesse no B2B Stack Manager! Enviaremos um e-mail para dar continuidade.',
        'info',
      )
      postSubmit && postSubmit()
    } catch (error) {
      showAlert('Erro ao enviar solicitação.', 'error')
    }
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { termsAndCondition, ...dataWithoutTerms } = data

    const dataWithPlan = {
      ...dataWithoutTerms,
    }

    if (email) {
      dataWithPlan.email = email
    }

    handleRequest(dataWithPlan)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={4}>
        <Typography
          variant="body1"
          sx={{
            color: 'text.secondary',
            mb: 4,
          }}
        >
          {email}
        </Typography>

        <InputLabel
          sx={{
            textAlign: 'left',
            fontWeight: '600',
            color: palette.text.primary,
            my: 1,
          }}
        >
          Nome
        </InputLabel>
        <Controller
          name={'name'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{
                width: '100%',
              }}
              onChange={onChange}
              value={value}
              placeholder={'Digite seu nome completo'}
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
            />
          )}
        />
      </Box>
      {!email && (
        <Box mt={4}>
          <InputLabel
            sx={{
              textAlign: 'left',
              fontWeight: '600',
              color: palette.text.primary,
              my: 1,
            }}
          >
            E-mail corporativo
          </InputLabel>
          <Controller
            name={'email'}
            control={control}
            defaultValue={email}
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{
                  width: '100%',
                }}
                onChange={onChange}
                value={value}
                placeholder={'Digite o seu e-mail profissional'}
                error={!!formState.errors.email}
                helperText={formState.errors.email?.message}
              />
            )}
          />
        </Box>
      )}
      <Box mt={4}>
        <InputLabel
          sx={{
            textAlign: 'left',
            fontWeight: '600',
            color: palette.text.primary,
            my: 1,
          }}
        >
          Telefone
        </InputLabel>
        <Controller
          name={'phone'}
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{
                width: '100%',
              }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9()*-]/g, '')
                phoneMask(e)
                onChange(e)
              }}
              value={value}
              inputProps={{
                maxLength: 15,
              }}
              placeholder={'Digite seu telefone para contato profissional '}
              error={!!formState.errors.phone}
              helperText={formState.errors.phone?.message}
            />
          )}
        />
      </Box>

      <Box mt={4}>
        <InputLabel
          sx={{
            textAlign: 'left',
            fontWeight: '600',
            color: palette.text.primary,
            my: 1,
          }}
        >
          Empresa
        </InputLabel>
        <Controller
          name={'companyName'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              sx={{
                width: '100%',
                textAlign: 'left',
              }}
              onChange={onChange}
              value={value}
              placeholder={'Escreva o nome de sua empresa'}
              error={!!formState.errors.companyName}
              helperText={formState.errors.companyName?.message}
            />
          )}
        />
      </Box>
      <Box mt={4}>
        <InputLabel
          sx={{
            textAlign: 'left',
            fontWeight: '600',
            color: palette.text.primary,
            my: 1,
          }}
        >
          Número de funcionários
        </InputLabel>
        <Controller
          name="companySize"
          control={control}
          defaultValue={companySizeOptions[0].value}
          render={({ field: { ref, ...rest } }) => (
            <InputSelect
              {...rest}
              inputRef={ref}
              fullWidth
              options={companySizeOptions}
              required
            />
          )}
        />
      </Box>

      <Box
        my={2}
        sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
      >
        <Controller
          name={'termsAndCondition'}
          control={control}
          defaultValue={false}
          render={({ field: { value, ref, ...field } }) => (
            <Checkbox
              {...field}
              inputRef={ref}
              checked={!!value}
              color="primary"
              size={'medium'}
              disableRipple
              required
            />
          )}
        />

        <Typography variant="body1">
          Li e concordo com {' os '}{' '}
          <a
            href="/terms-and-conditions"
            target="_blank"
            style={{
              color: palette.primary.main,
              textDecoration: 'none',
            }}
          >
            Termos de uso
          </a>
          {' da '} B2BStack.
        </Typography>
      </Box>
      <Box>
        <Button fullWidth type="submit">
          Enviar
        </Button>
      </Box>
    </form>
  )
}
export default RequestContactForm
